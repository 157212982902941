@tailwind base;
@tailwind components;
@tailwind utilities;

/*
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
*/

html {
    --foreground-rgb: 22, 22, 22;
    --background-rgb: 240, 240, 240;
}

.dark {
    --foreground-rgb: 240, 240, 240;
    --background-rgb: 45, 41, 38;
}

html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    /*  font-size: 62.5%;*/

    font-family: 'Source Sans 3', sans-serif;
    /*font-variation-settings: 'wght' 400;*/

    /* overscroll-behavior:  */
    scroll-behavior: smooth;

    overflow-x: hidden;
}

body {
    color: rgb(var(--foreground-rgb));
    background: rgb(var(--background-rgb));
}

.u-grid {
    background-image:
        repeating-linear-gradient(
            rgba(0, 0, 0, 0.08) 0 1px,
            transparent 1px 100%
        ),
        repeating-linear-gradient(
            90deg,
            rgba(0, 0, 0, 0.03) 0 1px,
            transparent 1px 100%
        );
    background-size: 40px 40px;
}

@media (min-width: 768px) {
    /*Square size*/
    .u-grid {
        background-size: 60px 60px;
    }

    /*html, body {
        scrollbar-gutter: stable;
    }*/
}

svg.icon-tabler {
    fill: none;
}

.text-vertical-lr {
    writing-mode: vertical-lr;
}

.flippable {
    display: grid;
    grid-template-columns: 1;
    grid-template-rows: 1;
}

.flippable .front,
.flippable .back {
    width: 100%;
    height: 100%;
    overflow: hidden;
    backface-visibility: hidden;

    grid-column-start: 1;
    grid-row-start: 1;

    transition: transform 0.25s ease-in-out;
}

.flippable .back {
    transform: rotateY(180deg);
}

.flippable:hover > .back {
    transform: perspective(600px) rotateY(0deg);
}

.flippable:hover > .front {
    transform: perspective(600px) rotateY(-180deg);
}

.text-stroke {
    -webkit-text-stroke: 1px #000;
    /*    text-shadow:
    -1px -1px 0 #000,  
     1px -1px 0 #000,
     -1px 1px 0 #000,
      1px 1px 0 #000;*/
}

.focus-corners {
    background:
        linear-gradient(to right, currentcolor 1px, transparent 1px) 0 0,
        linear-gradient(to right, currentcolor 1px, transparent 1px) 0 100%,
        linear-gradient(to left, currentcolor 1px, transparent 1px) 100% 0,
        linear-gradient(to left, currentcolor 1px, transparent 1px) 100% 100%,
        linear-gradient(to bottom, currentcolor 1px, transparent 1px) 0 0,
        linear-gradient(to bottom, currentcolor 1px, transparent 1px) 100% 0,
        linear-gradient(to top, currentcolor 1px, transparent 1px) 0 100%,
        linear-gradient(to top, currentcolor 1px, transparent 1px) 100% 100%;

    background-repeat: no-repeat;
    background-size: 20px 20px;
}
/* Override when variable font support is detected to avoid Double-bold effect in modern browsers */
/* @supports (font-variation-settings: normal) {
    .font-thin {
        font-variation-settings: 'wght' 100;
        font-weight: normal;
    }
    .font-extralight {
        font-variation-settings: 'wght' 200;
        font-weight: normal;
    }
    .font-light {
        font-variation-settings: 'wght' 300;
        font-weight: normal;
    }
    .font-normal {
        font-variation-settings: 'wght' 400;
        font-weight: normal;
    }
    .font-medium {
        font-variation-settings: 'wght' 500;
        font-weight: normal;
    }
    .font-semibold {
        font-variation-settings: 'wght' 600;
        font-weight: normal;
    }
    .font-bold {
        font-variation-settings: 'wght' 700;
        font-weight: normal;
    }
    .font-extrabold {
        font-variation-settings: 'wght' 800;
        font-weight: normal;
    }
    .font-black {
        font-variation-settings: 'wght' 900;
        font-weight: normal;
    }
}
 */

.scroll-locked {
    overflow: hidden;
}

:root {
    --toastify-color-progress-success: #aaa;
}

@media (min-width: 768px) {
    .Toastify__toast-container--top-center {
        right: 2rem;
        left: auto;
        transform: none;
    }
}

/* @media (min-width: 768px) { */
input::-webkit-search-cancel-button {
    /* display: none; */
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    width: 10px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
    background-size: 10px 10px;
    opacity: 0.5;
}
/* } */
